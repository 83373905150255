import { UPSELL_STATE } from '@utils/context/Order';
import { translate } from '@utils/locales';
import { LOCALE_CODES } from '..';

export const getPairCareModalCopy = (upsellState: UPSELL_STATE, locale: string): { LINE_1: string; LINE_2: string } => {
	const PAIR_CARE_MODAL_COPY = {
		[UPSELL_STATE.AVAILABLE]: {
			LINE_1: translate('pair-care-upsell-state-available-line-1', locale),
			LINE_2: translate('pair-care-upsell-state-available-line-2', locale),
		},
		[UPSELL_STATE.INELIGABLE]: {
			LINE_1: translate('pair-care-upsell-state-ineligible-line-1', locale),
			LINE_2: translate('pair-care-upsell-state-ineligible-line-2', locale),
		},
		[UPSELL_STATE.PURCHASED]: {
			LINE_1: translate('pair-care-upsell-state-purchased-line-1', locale),
			LINE_2: translate('pair-care-upsell-state-purchased-line-2', locale),
		},
		[UPSELL_STATE.PENDING]: {
			LINE_1: translate('pair-care-upsell-state-pending-line-1', locale),
			LINE_2: translate('pair-care-upsell-state-pending-line-2', locale),
		},
	};
	return PAIR_CARE_MODAL_COPY[upsellState];
};

export const getPairCareModalButtonCopy = (upsellState: UPSELL_STATE, locale: string): string => {
	const PAIR_CARE_MODAL_BUTTON_COPY: Record<UPSELL_STATE, string> = {
		[UPSELL_STATE.INELIGABLE]: translate('plus-add', locale),
		[UPSELL_STATE.AVAILABLE]: translate('plus-add', locale),
		[UPSELL_STATE.PENDING]: translate('pending', locale),
		[UPSELL_STATE.PURCHASED]: translate('added', locale),
	};
	return PAIR_CARE_MODAL_BUTTON_COPY[upsellState];
};

export const PAIR_CARE_UPSELL_COPY: Record<UPSELL_STATE, string> = {
	[UPSELL_STATE.INELIGABLE]: 'Unfortunately, your order is 30 days past the purchase date and you can no longer add PairCare.',
	[UPSELL_STATE.AVAILABLE]: "There's still time to add on PairCare – make sure to protect your Pair!",
	[UPSELL_STATE.PENDING]: "We're just waiting on payment before we can add PairCare to your order. Check your inbox!",
	[UPSELL_STATE.PURCHASED]: "No worries – you've already guaranteed the protection of your Pair!",
};

export const getPasswordErrorMessage = (length: number, locale: string = LOCALE_CODES.US): string => {
	return translate('text-length-validation', locale, { length });
};
