import { COURIER_OPTIONS } from '@constants';
import { Body, CanadaPost, DHL, FedEx, LabelText, Landmark, Paragraph, USPS, UPS } from '@components';
import { useTranslation } from '@utils/index';
import styles from './TrackingNumber.module.scss';

type TrackingNumberProps = {
	company: string;
	number: string;
	url: string;
};

const getTranslatedTexts = translator => {
	return {
		contactUsButtonTitle: translator('contact-us-button-title'),
		contactUs: translator('contact-us'),
		trackingNumber: translator('tracking-number'),
	};
};

function determineCourierIcon(company) {
	const casedCompany = company.toUpperCase();

	if (casedCompany.includes(COURIER_OPTIONS.DHL)) return <DHL />;
	if (casedCompany.includes(COURIER_OPTIONS.UPS)) return <UPS />;
	if (casedCompany.includes(COURIER_OPTIONS.USPS)) return <USPS />;
	if (casedCompany.includes(COURIER_OPTIONS.FEDEX)) return <FedEx />;
	if (casedCompany.includes(COURIER_OPTIONS.LANDMARK)) return <Landmark />;
	if (casedCompany.includes(COURIER_OPTIONS.CANADA_POST)) return <CanadaPost />;
	return company;
}

const TrackingNumber = ({ company, number, url }: TrackingNumberProps) => {
	const { translator } = useTranslation();
	const translations = getTranslatedTexts(translator);

	return (
		<div className={styles.container} data-status={company ? 'active' : 'cancelled'}>
			{company && number ? (
				<>
					<div className={styles.icon}>{determineCourierIcon(company)}</div>
					<LabelText style={{ marginBottom: '0.4rem' }}>{translations.trackingNumber}</LabelText>
					<a className={styles.number} href={url} target='_blank' rel='noopener noreferrer'>
						<Body>{number}</Body>
					</a>
				</>
			) : (
				<button
					type='button'
					// eslint-disable-next-line @typescript-eslint/ban-ts-comment
					// @ts-ignore
					onClick={() => window.adaEmbed && window?.adaEmbed?.toggle()}
					className={styles.contact}
					title={translations.contactUsButtonTitle}
				>
					<Paragraph>{translations.contactUs}</Paragraph>
				</button>
			)}
		</div>
	);
};

export default TrackingNumber;
